<template>
  <div style="background-color: #fff;">
    <v-app-bar app elevate-on-scroll color="white" flat light style="padding-top: calc(15px + env(safe-area-inset-top)); height: calc(80px + env(safe-area-inset-top));">
      <a to="/">
        <v-img
          alt="Finde Deinen Sport Logo"
          class="shrink mr-2"
          src="/img/2x/finde-deinen-sport@2x.png"
          transition="scale-transition"
          :width="$vuetify.breakpoint.mdAndUp ? '250' : '200'"
          style="position: absolute; top: -15px; left: 0;"
          @click="home()"
        />
      </a>
      <v-spacer></v-spacer>
      <div v-if="user.loggedIn && $vuetify.breakpoint.mdAndUp">
        <v-btn
          text
          large
          rounded
          class="mx-2"
          color="primary"
          v-for="(item, index) in desktop_items"
          :key="index"
          exact
          :to="`${item.link}`"
        >
          <font-awesome-icon class="mr-2" :icon="item.icon" />
          {{ item.title }}
        </v-btn>
      </div>
      
      <v-spacer></v-spacer>

      <v-btn text large rounded v-if="!user.loggedIn" to="/login">
        <v-icon class="mr-1">mdi-lock</v-icon>
        Login
      </v-btn>
      <v-menu offset-y content-class="rounded-xl">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-if="user.loggedIn"
            :rounded="$vuetify.breakpoint.mdAndUp"
            :fab="!$vuetify.breakpoint.mdAndUp"
            v-bind="attrs"
            v-on="on"
            class="pl-0"
          >
            <v-avatar :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''">
              <v-img :src="user.person.avatar"></v-img>
            </v-avatar>
            <span v-if="$vuetify.breakpoint.mdAndUp">{{ user.data.vorname }} {{ user.data.nachname }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/admin" v-if="user.data.admin" @click="user.show_admin = true">
            <v-list-item-title><v-icon class="mr-2">mdi-swap-horizontal-bold</v-icon>User Ansicht</v-list-item-title>
          </v-list-item>
          <v-divider v-if="user.data.admin"></v-divider>
          <v-list-item to="/eltern/aktivierung" v-if="!user.data.activated">
            <v-list-item-title>Account aktivieren</v-list-item-title>
          </v-list-item>
          <v-list-item to="/eltern/mein-profil">
            <v-list-item-title>Mein Profil</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item v-if="user.loggedIn">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Test Person</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense v-if="user.loggedIn">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation
      app
      v-if="$vuetify.breakpoint.smAndDown && user.loggedIn"
      grow
      v-model="mobileNav"
      color="primary"
      style="margin-top: 4vw; margin-left: 4vw; margin-right: 4vw; margin-bottom: calc(4vw + env(safe-area-inset-bottom)); width: 92vw; border-radius: 25px; height: calc(8vh); background: rgba(255, 255, 255, 0.99);"
    >
      <v-btn
        v-for="(item, index) in items"
        :key="index"
        :to="`${item.link}`"
        exact
      >
        <font-awesome-icon :class="$route.path == item.link ? 'primary--text' : ''" :icon="$route.path == item.link ? 'fa-solid '+item.icon : 'fa-light '+item.icon" size="2x" />
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/index'
import logout from '@/auth/auth'
import router from '@/router'

export default {
  name: 'Navigation',
  data: () => ({
    drawer: false,
    mobileNav: '',
    value: 1,
    show_admin: false,
    items: [
      {
        icon: 'fa-grid-2',
        title: 'Startseite',
        link: '/eltern',
      },
      {
        icon: 'fa-magnifying-glass',
        title: 'Entdecken',
        link: '/eltern/entdecken',
      },
      {
        icon: 'fa-calendar-circle-user',
        title: 'Meine Termine',
        link: '/eltern/meine-termine',
      },
      {
        icon: 'fa-user',
        title: 'Mein Profil',
        link: '/eltern/mein-profil',
      },
    ],
    desktop_items: [
      {
        icon: 'fa-light fa-grid-2',
        title: 'Sportangebote',
        link: '/eltern',
      },
      {
        icon: 'fa-light fa-magnifying-glass',
        title: 'Entdecken',
        link: '/eltern/entdecken',
      },
      {
        icon: 'fa-light fa-calendar-circle-user',
        title: 'Meine Termine',
        link: '/eltern/meine-termine',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      auth: 'auth',
    }),
  },
  methods: {
    logout() {
      logout.logout(this.auth)
    },
    switch_admin(){
      this.$emit('switch')
    },
    home(){
      router.push('/')
    }
  },
}
</script>

<style lang="scss">
.nav-active {
  background:#2196f3 !important;
}
.nav-inactive {
  border-top: 4px solid #000;
}
</style>
