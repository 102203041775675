<template>
  <div>
    <v-app-bar app color="white" flat light style="margin-top: calc(env(safe-area-inset-top) + 15px);">
      <a to="/">
        <v-img
          alt="Finde Deinen Sport Logo"
          class="shrink mr-2"
          src="/img/2x/finde-deinen-sport@2x.png"
          transition="scale-transition"
          :width="$vuetify.breakpoint.mdAndUp ? '250' : '200'"
          style="position: absolute; top: -15px; left: 0;"
        />
      </a>
      <v-spacer v-if="user.loggedIn && $vuetify.breakpoint.mdAndUp"></v-spacer>
      <div v-if="user.loggedIn && $vuetify.breakpoint.mdAndUp">
        <v-btn
          text
          large
          rounded
          color="primary"
          v-for="(item, index) in desktop_items"
          :key="index"
          :to="`${item.link}`"
        >
          <v-icon class="mr-2">{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </div>
      
      <v-spacer></v-spacer>

      <v-btn text large rounded v-if="!user.loggedIn" to="/login">
        <v-icon class="mr-1">mdi-lock</v-icon>
        Login
      </v-btn>
      <v-menu offset-y content-class="rounded-xl">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :text="$vuetify.breakpoint.mdAndUp"
            :fab="!$vuetify.breakpoint.mdAndUp"
            v-if="user.loggedIn"
            rounded
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''">
              <v-img :src="user.person.avatar"></v-img>
            </v-avatar>
            <span v-if="$vuetify.breakpoint.mdAndUp">{{ user.data.vorname }} {{ user.data.nachname }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/user" v-if="user.data.admin" @click="user.show_admin = false">
            <v-list-item-title><v-icon class="mr-2">mdi-swap-horizontal-bold</v-icon>Admin Ansicht</v-list-item-title>
          </v-list-item>
          <v-divider v-if="user.data.admin"></v-divider>
          <v-list-item to="/mein-profil">
            <v-list-item-title>Mein Profil</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item v-if="user.loggedIn">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Test Person</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense v-if="user.loggedIn">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation
      app
      v-if="$vuetify.breakpoint.smAndDown && user.loggedIn"
      grow
      color="primary"
      style="height: 10vh;"
    >
      <v-btn
        v-for="(item, index) in items"
        :key="index"
        :to="`${item.link}`"
        style="padding-bottom: env(safe-area-inset-bottom);"
      >
        <span>{{ item.title }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store/index'
import logout from '../../../auth/auth'

export default {
  name: 'Navigation',
  data: () => ({
    drawer: false,
    value: 1,
    show_admin: true,
    items: [
      {
        icon: 'mdi-feature-search',
        title: 'Sportangebote',
        link: '/admin/sportangebote',
      },
      {
        icon: 'mdi-shield',
        title: 'Vereine',
        link: '/admin/vereine',
      },
      {
        icon: 'mdi-view-dashboard-edit',
        title: 'Sportarten',
        link: '/admin/sportarten',
      }
    ],
    desktop_items: [
      {
        icon: 'mdi-feature-search',
        title: 'Sportangebote',
        link: '/admin/sportangebote',
      },
      {
        icon: 'mdi-shield',
        title: 'Vereine',
        link: '/admin/vereine',
      },
      {
        icon: 'mdi-view-dashboard-edit',
        title: 'Sportarten',
        link: '/admin/sportarten',
      }
    ],
    //
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      auth: 'auth',
    }),
  },
  methods: {
    logout() {
      logout.logout(this.auth)
    },
    switch_admin(){
      this.$emit('switch')
    }
  },
}
</script>
