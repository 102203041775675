<template>
  <div id="navigation">
    <navigation
      v-if="!user.data || (user.data && !user.data.eltern && !user.data.verein && !user.data.admin || !user.show_admin)"
    ></navigation>
    <ElternNavigation
      v-if="user.data && user.data.eltern && !user.data.admin"
    ></ElternNavigation>
    <AdminNavigation v-if="user.data && user.data.admin && user.show_admin"></AdminNavigation>
    <VereinNavigation v-if="user.data && user.data.verein && $vuetify.breakpoint.mdAndDown"></VereinNavigation>
    <VereinLgNavigation v-if="user.data && user.data.verein && $vuetify.breakpoint.lgAndUp"></VereinLgNavigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from '../User/Navigation/nav.vue'
import AdminNavigation from '../Admin/Navigation/nav.vue'
import VereinNavigation from '../Verein/Navigation/nav.vue'
import VereinLgNavigation from '../Verein/Navigation/nav_lg.vue'
import ElternNavigation from '../Eltern/Navigation/nav.vue'

export default {
  name: 'Navigation-Page',
  components: {
    Navigation,
    AdminNavigation,
    VereinNavigation,
    VereinLgNavigation,
    ElternNavigation
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  mounted() {
    if(this.$route.path.includes('user')){
      this.user.show_admin = false
    }
    if(this.$route.path.includes('admin')){
      this.user.show_admin = true
    }
  }
}
</script>
