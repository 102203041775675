<template>
  <v-app-bar v-if="nav.show" app color="white" flat light>
    <v-btn v-if="nav.back_link" icon exact class="mr-2" :to="nav.back_link"><font-awesome-icon icon="fa-light fa-arrow-left" size="xl" /></v-btn><h2 class="primary--text"><span class="font-weight-light">{{nav.title}} |</span> {{nav.subtitle}}</h2>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store/index'
import logout from '../../../auth/auth'

export default {
  name: 'Navigation',
  data: () => ({
    value: 1,
    show_admin: true,
    items: [
      {
        icon: 'mdi-feature-search',
        title: 'Sportangebote',
        link: '/verein/sportangebote',
      },
      {
        icon: 'mdi-shield',
        title: 'Mein Verein',
        link: '/verein/mein-verein',
      },
    ],
    desktop_items: [
      {
        icon: 'mdi-feature-search',
        title: 'Sportangebote',
        link: '/verein/sportangebote',
      },
      {
        icon: 'mdi-shield',
        title: 'Mein Verein',
        link: '/verein/mein-verein',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      auth: 'auth',
      nav_expand: 'nav_expand'
    }),
    nav(){
      if(this.$route.meta.nav){
        return this.$route.meta.nav
      }
      else if(this.nav_expand.show){
        return this.nav_expand
      }
      else {
        return {show: false}
      }
    }
  },
  methods: {
    logout() {
      logout.logout(this.auth)
    },
    switch_admin(){
      this.$emit('switch')
    },
  },
}
</script>
