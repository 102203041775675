<template>
  <v-app>
    <sidebar v-if="user.data.verein" />
    <navigation></navigation>
    <v-main style="background-image: linear-gradient(180deg, #ffffff 36%, #747095 100%); background-size: cover; background-attachment: fixed;">
      <loader v-if="user.loader"></loader>
      <div v-if="!user.loader">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer app inset v-if="$vuetify.breakpoint.lgAndUp">
      <v-row justify="space-between">
        <v-col cols="auto" align-self="center">
          <font-awesome-icon icon="fa-thin fa-copyright" class="mr-2" />2022 - StadtSportVerband Greven e.V.
        </v-col>
        <v-col cols="auto" align-self="center">
          <v-row justify="center" no-gutters>
            <v-btn text rounded to="/impressum" class="mx-2">Impressum</v-btn>
            <v-btn text rounded to="/datenschutz" class="mx-2">Datenschutz</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation/index.vue'
import sidebar from '@/components/Verein/Navigation/sidebar.vue'
import { mapGetters } from 'vuex'
import store from './store/index'
import Loader from './components/Loader/loader.vue'
import { StatusBar, Style } from '@capacitor/status-bar';

export default {
  name: 'App',
  components: {
    Loader,
    Navigation,
    sidebar
  },
  async mounted(){
    if(process.env.NODE_ENV === 'app'){
      // Display content under transparent status bar (Android only)
      StatusBar.setOverlaysWebView({ overlay: true })
      await StatusBar.setStyle({ style: Style.Light })
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
}
</script>

<style>
*::-webkit-scrollbar {
  display: none;
}

.v-app-bar {
  padding-top: calc(30px + env(safe-area-inset-top)) !important; 
  height: calc(80px + env(safe-area-inset-top)) !important;
}

.hidevscroll-scrollbar {
  display: none !important;
}

/* Removes scrollbar of Bookmarks / history menulist (the ones that appear when u click the library button*/
.panel-subview-body {
  scrollbar-width: none !important;
}

/* Removes Library scrollbars */
@-moz-document url(chrome://browser/content/places/places.xul) {
  .hidevscroll-scrollbar {
    display: none !important;
  }
}
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  color: #000;
}
.primary-toast.swal2-icon-success {
  background-color: #29235c !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  color: #fff;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  color: #fff;
}

.v-card--link:focus:before {
  opacity: 0;
}


.v-sheet.v-card {
  border-radius: 35px;
}

.h-100 {
  height: 100%;
}
</style>
