<template>
  <v-container fluid>
    <v-overlay class="text-center" color="rgba(255,255,255,0.8)" opacity="1">
      <v-row justify="center">
        <v-col cols="auto" class="pa-0" align-self="center">
          <v-progress-circular
            indeterminate
            color="primary"
            width="4"
            size="80"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'Ladebildschirm',
}
</script>
