import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(fas, far, fal, fad, fat)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'faSvg'
    },
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: '#29235c',
                secondary: '#747095',
                accent3: '#c82c4a',
                accent: '#eec405',
                accent2: '#e83d95',
                background: '#212121',
                grey: '#424242',
                elements: '#424242'
            },
            light: {
                primary: '#29235c',
                secondary: '#747095',
                accent3: '#c82c4a',
                accent: '#eec405',
                accent2: '#e83d95',
                background: '#F5F5F5',
                grey: '#424242',
                elements: '#CFD8DC'
            }
        }
        
    }
});
