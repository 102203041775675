import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router/index'
import vueHeadful from 'vue-headful'
import store from './store'
import vuetify from './plugins/vuetify'
import VImageInput from 'vuetify-image-input/a-la-carte'
import { Plugins } from '@capacitor/core'
const { SplashScreen } = Plugins
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false
Vue.component('vue-headful', vueHeadful)
Vue.component('VImageInput', VImageInput)
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted(){
    if(SplashScreen){
      SplashScreen.hide()
    }
  }
}).$mount('#app')
