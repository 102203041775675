import store from '../store'
import router from '../router/index'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signOut, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth'

let snap
let vereinsnap

export function getUser() {
  return firebase.auth().currentUser
}
export async function logout(auth) {
  const signout = await signOut(auth)
  store.dispatch('showLoggedOut')
  setTimeout(() => {
    router.push('/')
  }, 500)
}
export default { getUser, logout }

/*
if (process.env.NODE_ENV == 'production') {
  fetch('/__/firebase/init.json').then(async (response) => {
    let config = await response.json()
    const firebaseApp = initializeApp(config)
    const auth = initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
      });

    onAuthStateChanged(auth, (user) => {
      store.dispatch('set_app', {app: firebaseApp})
      if (user) {
        if (!user.isAnonymous) {
          var userprovider = auth.currentUser
  
          // GET PROVIDER INFORMATIONS
          var providername = 'password'
          if (userprovider != null) {
            userprovider.providerData.forEach((profile) => {
              providername = profile.providerId
            })
          }
  
          user.userdata = {
            provider: providername,
            uid: user.uid,
          }
          store.dispatch('fetchUser', user)
        } else {
          store.dispatch('anonymerUser')
        }
      } else {
        store.dispatch('fetchUser', user)
      }
    })
  })
} else {**/
  const firebaseConfig = {
    apiKey: "AIzaSyA31rfqf2UiKkRHAfQs_643lyiUJ6xWwFM",
    authDomain: "finde-deinen-sport.firebaseapp.com",
    projectId: "finde-deinen-sport",
    storageBucket: "finde-deinen-sport.appspot.com",
    messagingSenderId: "461840330224",
    appId: "1:461840330224:web:600e695533db3ee2bbc135",
    measurementId: "G-EN4L04GM61"
  };

  const firebaseApp = initializeApp(firebaseConfig)
  //const analytics = getAnalytics(firebaseApp)
  

  const auth = initializeAuth(firebaseApp, {
    persistence: indexedDBLocalPersistence,
    });

  onAuthStateChanged(auth, (user) => {
    store.dispatch('set_app', {app: firebaseApp})
    if (user) {
      if (!user.isAnonymous) {
        var userprovider = auth.currentUser

        // GET PROVIDER INFORMATIONS
        var providername = 'password'
        if (userprovider != null) {
          userprovider.providerData.forEach((profile) => {
            providername = profile.providerId
          })
        }

        user.userdata = {
          provider: providername,
          uid: user.uid,
        }
        store.dispatch('fetchUser', user)
      } else {
        store.dispatch('anonymerUser')
      }
    } else {
      store.dispatch('fetchUser', user)
    }
  })
//}
