const ValidationModule = {
    state: {
        rules: { 
            required: (value) => !!value || 'Bitte gib diese Daten ein',
            name: (v) =>
                v && v.length >= 2 ? true : 'Bitte gib einen gültigen Namen ein',
            gross: (v) => v && v.match(/^[A-Z]+/) ? true : 'Bitte schreibe den 1. Buchstaben in GROSSBUCHSTABEN',
            street: (v) =>
                v && v.length >= 5 ? true : 'Bitte gib einen gültigen Straßennamen ein',
            streetnr: (v) => v &&
                v.match(/[0-9]{1,}/)
                ? true
                : 'Bitte gib eine gültige Hausnummer ein',
            telefon: (v) =>
                v && v.match(/[0-9]{5,}/)
                ? true
                : 'Bitte gib eine gültige Telefonnummer ein',
            plz: (v) =>
                v && v.match(/[0-9]{5}/)
                ? true
                : 'Bitte gib eine gültige Postleitzahl ein',
            email: (v) =>
                v && v.match(/^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/)
                ? true
                : 'Bitte gib eine gültige E-Mail Adresse ein',
            password: (v) => v && v.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/) ? true : 'Das Passwort sollte mindestens 8 Zeichen lang sein und einen Großbuchstaben und eine Zahl enthalten.',
            geburtsdatum: (v) => v && v.match(/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/) ? true : 'Bitte gib das Geburtsdatum im Format TT.MM.JJJJ ein.'
        }
    },
    getters: {
        rules(state){
            return state.rules
        }
    }
}

export default ValidationModule