import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  onSnapshot,
} from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import ValidationModule from './validation'

Vue.use(Vuex)

const getAge = (DOB) => {
  if(DOB){
    var d_o_b = DOB.split('.')
    if(d_o_b.length == 3){
      var today = new Date();
      var birthDate = new Date(d_o_b[2]+'-'+d_o_b[1]+'-'+d_o_b[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }
      return age;
    }
    d_o_b = DOB.split('-')
    if(d_o_b.length == 3){
      var today = new Date();
      var birthDate = new Date(d_o_b[0]+'-'+d_o_b[1]+'-'+d_o_b[2]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }
      return age;
    }
  }
  return false
}

const ietf_date = (datum, uhrzeit) => {
  if(datum){
    if(uhrzeit){
      return datum+'T'+uhrzeit+':00.000+02:00'
    }
    else {
      return datum+'T00:00:00.000+02:00'
    }
  }
  return datum
}

const generateTermine = (zeitraeume) => {
  const wochentage = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']

  if(Array.isArray(zeitraeume)){
    var termine = []
    zeitraeume.forEach(zeitraum => {
      if(wochentage.includes(zeitraum.wochentag) && zeitraum.datum.von < zeitraum.datum.bis){
        var von_datum = new Date(ietf_date(zeitraum.datum.von, zeitraum.uhrzeit.beginn))
        var bis_datum = new Date(ietf_date(zeitraum.datum.bis, zeitraum.uhrzeit.beginn))

        var current_date = new Date()
        while(von_datum.getTime() < bis_datum.getTime()){
          if(current_date.getTime() < von_datum.getTime()){
            termine.push({
              wochentag: wochentage[von_datum.getDay()],
              datum: von_datum.getFullYear()+'-'+(von_datum.getMonth()+1).toString().padStart(2, '0')+'-'+von_datum.getDate().toString().padStart(2, '0'),
              datum_format: von_datum.getDate().toString().padStart(2, '0')+'.'+(von_datum.getMonth()+1).toString().padStart(2, '0')+'.'+von_datum.getFullYear(),
              uhrzeit: {
                beginn: zeitraum.uhrzeit.beginn,
                ende: zeitraum.uhrzeit.ende
              },
              timestamp: von_datum.getTime()
            })
          }
          von_datum.setDate(von_datum.getDate() + 7)
        }
      } 
    })
    return termine
  }
}

const load_vereine = (state) => {
  state.vereine.snap = onSnapshot(
    query(collection(state.db, 'Vereine'), where('created', '==', true)),
    (vereine) => {
      state.vereine.data = []
      state.vereine.all = []
      var count = 0
      vereine.forEach((verein) => {
        var daten = verein.data()
        daten.id = verein.id
        daten.mitarbeiter = []
        daten.mitarbeiter_snap = onSnapshot(
          query(collection(state.db, 'Vereine/'+verein.id+'/Mitarbeiter'), where('vorname', '!=', '#')),
          (personen) => {
            daten.mitarbeiter = []
            personen.forEach(person => {
              daten.mitarbeiter.push(Object.assign({id: person.id}, person.data()))
            })
          }
        )
        state.vereine.all.push(daten)
        if (daten.name) {
          state.vereine.data.push(daten)
        }
        if(state.user?.data?.verein == verein.id){
          state.user.data.verein = daten
        }
        if(count+1 == vereine.size){
          state.loader.vereine = false
        }
        else {
          count++
        }
      })
    },
  )
}

const load_sportarten = (state) => {
  onSnapshot(
    query(collection(state.db, 'Sportarten'), where('name', '!=', '#')),
    (items) => {
      state.sportarten.data = []
      state.sportarten.all = []
      var count = 0
      items.forEach((item) => {
        var daten = item.data()
        daten.id = item.id
        state.sportarten.all.push(daten)
        if (daten.created) {
          state.sportarten.data.push(daten)
        }
        if(count+1 == items.size){
          state.loader.sportarten = false
        }
        else {
          count++
        }
      })
    },
  )
}

const load_anmeldungen = (state) => {
  if(state.sportangebote.all.length == 0){
    setTimeout(() => {
      load_anmeldungen(state)
    }, 100)
  }
  else {
    onSnapshot(
      query(
        collection(state.db, 'Anmeldungen'),
        where('teilnehmer', '!=', ''),
      ),
      (anmeldungen) => {
        state.user.anmeldungen = []
        state.anmeldungen = []
        state.loader.anmeldungen = true
        var count = 0
        anmeldungen.forEach((anmeldung, index) => {
          var daten = anmeldung.data()
          var angebot = state.sportangebote.all.find(item => item.id == daten.veranstaltung) 
          if(angebot){
            if(!angebot?.anmeldungen){
              angebot.anmeldungen = []
            }
            angebot.anmeldungen.push(anmeldung.data())
            state.anmeldungen.push(anmeldung.data())
            if(daten.teilnehmer == state.user.uid){
              daten.id = anmeldung.id
              daten.sportangebot = state.sportangebote.all.find(
                (item) => item.id === daten.veranstaltung,
              )
              state.user.anmeldungen.push(daten)
            }
          }
          if(count+1 == anmeldungen.size){
            state.loader.anmeldungen = false
          }
          else {
            count++
          }
        })
      },
    )
  }
}

const load_user = (state, data) => {
  if(state.sportarten.all.length == 0 || state.vereine.all.length == 0){
    setTimeout(() => {
      load_user(state, data)
    }, 100)
  }
  else {
    state.user.displayName = data.displayName
    state.user.photoUrl = data.photoUrl
    state.user.email = data.email
    state.user.uid = data.uid
    state.user.loggedIn = true
    state.user.snap = onSnapshot(
      doc(state.db, 'User', data.uid),
      (usersnap) => {
        if (usersnap.exists()) {
          state.user.data = usersnap.data()
          
          
          if(state.user.data.verein){
            if(state.vereine.all.length > 0 && state.user.data.verein){
              state.user.data.verein = state.vereine.all.find(item => item.id == state.user.data.verein)
            }
            onSnapshot(
              query(
                collection(state.db, 'Sportangebote'),
                where('verein', '==', state.user.data.verein.id),
              ),
              (angebote) => {
                state.sportangebote.all = []
                state.sportangebote.data = []
                var count = 0
                angebote.forEach((angebot) => {
                  var daten = angebot.data()
                  daten.id = angebot.id
                  daten.verein = state.vereine.data.find(
                    (item) => item.id === daten.verein,
                  )
                  daten.sportart = state.sportarten.all.find(
                    (item) => item.name == daten.sportart || item.id == daten.sportart
                  )
                  if(daten.zeitraum && daten.zeitraum.length > 0){
                    daten.zeitraum_termine = generateTermine(daten.zeitraum).sort((a,b) => a.datum < b.datum)
                  }
                  if(state.anmeldungen.length > 0){
                    daten.anmeldungen = state.anmeldungen.filter(item => item.veranstaltung == daten.id) || []
                  }
                  else {
                    daten.anmeldungen = []
                  }
                  state.sportangebote.all.push(daten)
                  if (daten.active) {
                    state.sportangebote.data.push(daten)
                  }
                  if(count+1 == angebote.size){
                    state.loader.sportangebote = false
                  }
                  else {
                    count++
                  }
                })
              },
            )
            onSnapshot(
              query(
                collection(state.db, 'User'),
                where('verein', '==', state.user.data.verein.id),
              ),
              (mitarbeiter) => {
                state.mitarbeiter = []
                mitarbeiter.forEach((person) => {
                  state.mitarbeiter.push(Object.assign({id: person.id}, person.data()))
                })
              },
            )
          }
          else {
            onSnapshot(
              query(
                collection(state.db, 'Sportangebote'),
                where('active', '==', true),
              ),
              (angebote) => {
                state.sportangebote.all = []
                state.sportangebote.data = []
                var count = 0
                angebote.forEach((angebot) => {
                  var daten = angebot.data()
                  daten.id = angebot.id
                  daten.verein = state.vereine.data.find(
                    (item) => item.id === daten.verein,
                  )
                  daten.sportart = state.sportarten.all.find(
                    (item) => item.name == daten.sportart || item.id == daten.sportart
                  )
                  if(daten.zeitraum && daten.zeitraum.length > 0){
                    daten.zeitraum_termine = generateTermine(daten.zeitraum).sort((a,b) => a.datum < b.datum)
                  }
                  if(state.anmeldungen?.length > 0){
                    daten.anmeldungen = state.anmeldungen.filter(item => item.veranstaltung == daten.id) || []
                  }
                  else {
                    daten.anmeldungen = []
                  }
                  state.sportangebote.all.push(daten)
                  if (daten.active) {
                    state.sportangebote.data.push(daten)
                  }
                  if(count+1 == angebote.size){
                    state.loader.sportangebote = false
                  }
                  else {
                    count++
                  }
                })
              },
            )
          }
          load_anmeldungen(state)

          if(router.currentRoute.fullPath == '/start' || router.currentRoute.fullPath == '/login'){
            if(state.user.data.admin){
              router.push('/admin')
            }
            else if(state.user.data.verein){
              router.push('/verein')
            }
            else {
              router.push('/user')
            }
          }
        }
        if(state.user.data.eltern){
          state.user.data.kinder.forEach(kind => {
            kind.alter = getAge(kind.geburtsdatum)
          })
        }
        else {
          state.user.data.alter = getAge(state.user.data.geburtsdatum)
        }

        disable_loader(state)
      },
    )
  }
}

const disable_loader = (state) => {
  if(state.loader.anmeldungen || state.loader.sportangebote || state.loader.sportarten || state.loader.vereine){
    setTimeout(() => {
      disable_loader(state)
    }, 100)
  }
  else {
    state.user.loader = false
  }
}

const store = new Vuex.Store({
  state: {
    db: getFirestore(),
    auth: getAuth(),
    app: '',
    loader: {
      anmeldungen: true,
      sportangebote: true,
      vereine: true,
      sportarten: true
    },
    user: {
      loggedIn: false,
      loader: true,
      loggedOut: false,
      show_admin: true,
      mobile_nav: false,
      data: '',
      displayName: '',
      photoUrl: '',
      email: '',
      uid: '',
      fullfill: '',
      person: {
        name: '',
        vorname: '',
        avatar: '/img/user.png',
      },
      snap: '',
      anmeldungen: []
    },
    mitarbeiter: [],
    vereine: {
      data: [],
      all: [],
      snap: '',
    },
    sportarten: {
      data: [],
      all: [],
      snap: '',
    },
    sportangebote: {
      data: [],
      all: [],
      snap: '',
    },
    anmeldungen: [],
    nav_expand: {
      title: '',
      show: false,
      back_link: ''
    }
  },
  getters: {
    nav_expand(state){
      return state.nav_expand
    },
    app(state){
      return state.app
    },
    db(state) {
      return state.db
    },
    auth(state) {
      return state.auth
    },
    user(state) {
      return state.user
    },
    vereine(state) {
      return state.vereine
    },
    sportarten(state) {
      return state.sportarten
    },
    sportangebote(state) {
      return state.sportangebote
    },
    anmeldungen(state) {
      return state.anmeldungen
    },
    mitarbeiter(state) {
      return state.mitarbeiter
    }
  },
  mutations: {
    SET_APP(state, values) {
      state.app = values.app
    },
    LOGIN(state, values) {
      state.user.person.vorname = values.person.vorname
      state.user.person.name = values.person.name
      state.user.loggedIn = true
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_LOGGED_OUT(state, value) {
      if (state.user.snap) {
        state.user.snap()
      }
      state.user = {
        loggedIn: false,
        loader: true,
        loggedOut: true,
        data: null,
      }
    },
    async SET_USER(state, data) {
      load_vereine(state)
      load_sportarten(state) 
      if (data) {
        load_user(state, data)
      } else {
        state.user.loader = false
        state.user.data = ''
      }
    },
    SET_SPORTANGEBOTE(state) {
      if(state.user.data.verein){
        onSnapshot(
          query(
            collection(state.db, 'Sportangebote'),
            where('verein', '==', state.user.data.verein.id),
          ),
          (angebote) => {
            state.sportangebote.all = []
            state.sportangebote.data = []
            angebote.forEach((angebot) => {
              var daten = angebot.data()
              daten.id = angebot.id
              daten.verein = state.vereine.data.find(
                (item) => item.id === daten.verein,
              )
              daten.sportart = state.sportarten.all.find(
                (item) => item.name == daten.sportart || item.id == daten.sportart
              )
              if(daten.zeitraum && daten.zeitraum.length > 0){
                daten.zeitraum_termine = generateTermine(daten.zeitraum).sort((a,b) => a.datum < b.datum)
              }
              if(state.anmeldungen.length > 0){
                daten.anmeldungen = state.anmeldungen.filter(item => item.veranstaltung == daten.id) || []
              }
              else {
                daten.anmeldungen = []
              }
              state.sportangebote.all.push(daten)
              if (daten.active) {
                state.sportangebote.data.push(daten)
              }
            })
          },
        )
      }
      else {
        onSnapshot(
          query(
            collection(state.db, 'Sportangebote'),
            where('active', '==', true),
          ),
          (angebote) => {
            state.sportangebote.all = []
            state.sportangebote.data = []
            angebote.forEach((angebot) => {
              var daten = angebot.data()
              daten.id = angebot.id
              daten.verein = state.vereine.data.find(
                (item) => item.id === daten.verein,
              )
              daten.sportart = state.sportarten.all.find(
                (item) => item.name == daten.sportart || item.id == daten.sportart
              )
              if(daten.zeitraum && daten.zeitraum.length > 0){
                daten.zeitraum_termine = generateTermine(daten.zeitraum).sort((a,b) => a.datum < b.datum)
              }
              if(state.anmeldungen.length > 0){
                daten.anmeldungen = state.anmeldungen.filter(item => item.veranstaltung == daten.id) || []
              }
              else {
                daten.anmeldungen = []
              }
              state.sportangebote.all.push(daten)
              if (daten.active) {
                state.sportangebote.data.push(daten)
              }
            })
          },
        )
      }
    }
  },
  actions: {
    set_app({ commit }, values){
      commit('SET_APP', values)
    },
    login({ commit }, values) {
      commit('LOGIN', values)
    },
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        var photo = '/img/user.png'
        if (user.photoURL) {
          photo = user.photoURL
        }
        if (user.userdata) {
          user.photoUrl = photo
          commit('SET_USER', user)
        } else {
          commit('SET_USER', {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoUrl: photo,
          })
        }
      } else {
        commit('SET_USER', null)
      }
    },
  },
  modules: {},
})

store.registerModule('ValidationModule', ValidationModule)

export default store
