import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../auth/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/',
        redirect: '/start',
      },
      {
        path: '/datenschutz',
        name: 'Datenschutzerklärung',
        component: () => import('../views/Datenschutz.vue')
      },
      {
        path: '/impressum',
        name: 'Impressum',
        component: () => import('../views/Impressum.vue')
      },
      {
        path: '/start',
        name: 'Landingpage',
        component: () => import('../components/Startseite.vue'),
      },
      {
        path: '/user',
        component: () => import('@/views/User/index.vue'),
        children: [
          {
            path: '',
            name: 'Startseite',
            component: () =>
              import('@/views/User/Sportangebote/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'meine-termine',
            name: 'Meine Termine',
            component: () => import('@/views/Termine.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'mein-profil',
            name: 'Mein Profil',
            component: () => import('@/views/Profil.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'entdecken',
            name: 'Entdecken',
            component: () => import('@/views/User/Entdecken/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'aktivierung',
            name: 'Aktivierung',
            component: () => import('@/views/User/MeinProfil/Aktivierung.vue'),
            meta: {
              requiresAuth: true,
            }
          }
        ],
      },
      {
        path: '/eltern',
        component: () => import('@/views/Eltern/index.vue'),
        children: [
          {
            path: '',
            name: 'Startseite',
            component: () =>
              import('@/views/Eltern/Sportangebote/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'meine-termine',
            name: 'Meine Termine',
            component: () => import('@/views/Termine.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'mein-profil',
            name: 'Mein Profil',
            component: () => import('@/views/Eltern/MeinProfil/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'mein-profil/kinder',
            name: 'Meine Kinder',
            component: () => import('@/views/Eltern/MeinProfil/Kinder/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'entdecken',
            name: 'Entdecken',
            component: () => import('@/views/Eltern/Entdecken/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: 'aktivierung',
            name: 'Aktivierung',
            component: () => import('@/views/Eltern/MeinProfil/Aktivierung.vue'),
            meta: {
              requiresAuth: true,
            }
          }
        ],
      },
      {
        path: '/jetzt-starten',
        name: 'Registrierung',
        component: () => import('@/views/Register.vue'),
      },

      {
        path: '/admin',
        component: () => import('@/views/Admin/index.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/Admin/Sportangebote/index.vue'),
            meta: {
              requiresAuth: true,
              admin: true
            }
          },
          {
            path: 'sportangebote',
            component: () =>
              import('@/views/Admin/Sportangebote/index.vue'),
            meta: {
                requiresAuth: true,
                admin: true
              }
          },
          {
            path: 'sportangebote/:sportangebot',
            component: () =>
              import('@/views/Admin/Sportangebote/sportangebot.vue'),
            meta: {
                requiresAuth: true,
                admin: true
              }
          },
          {
            path: 'vereine',
            component: () => import('@/views/Admin/Vereine/index.vue'),
            meta: {
              requiresAuth: true,
              admin: true
            }
          },
          {
            path: 'vereine/:verein',
            component: () => import('@/views/Admin/Vereine/verein.vue'),
            meta: {
              requiresAuth: true,
              admin: true
            }
          },
          {
            path: 'sportarten',
            component: () => import('@/views/Admin/Sportarten/index.vue'),
            meta: {
              requiresAuth: true,
              admin: true
            }
          },
          {
            path: 'sportarten/:sportart',
            component: () =>
              import('@/views/Admin/Sportarten/sportart.vue'),
            meta: {
                requiresAuth: true,
                admin: true
              }
          },
        ],
      },
      {
        path: '/verein',
        component: () => import('@/views/Verein/index.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/Verein/Sportangebote/start.vue'),
            meta: {
              requiresAuth: true,
              verein: true,
              nav: {
                title: 'Sportangebote',
                subtitle: 'Übersicht',
                show: true,
                back_link: ''
              }
            }
          },
          {
            path: 'sportangebote',
            component: () =>
              import('@/views/Verein/Sportangebote/start.vue'),
            meta: {
                requiresAuth: true,
                verein: true,
                nav: {
                  title: 'Sportangebote',
                  subtitle: 'Übersicht',
                  show: true,
                  back_link: ''
                }
              }
          },
          {
            path: 'sportangebote/erstellen',
            component: () =>
              import('@/views/Verein/Sportangebote/erstellen.vue'),
            meta: {
                requiresAuth: true,
                verein: true,
                nav: {
                  title: 'Sportangebote',
                  subtitle: 'Erstellen',
                  show: true,
                  back_link: '/verein/sportangebote'
                }
              }
          },
          {
            path: 'sportangebote/bearbeiten',
            component: () =>
              import('@/views/Verein/Sportangebote/bearbeiten.vue'),
              meta: {
                requiresAuth: true,
                verein: true,
                nav: {
                  title: 'Sportangebote',
                  subtitle: 'Bearbeiten',
                  show: true,
                  back_link: '/verein/sportangebote'
                }
              }
          },
          {
            path: 'sportangebote/bearbeiten/:sportangebot',
            component: () =>
              import('@/views/Verein/Sportangebote/sportangebot.vue'),
              meta: {
                requiresAuth: true,
                verein: true,
                nav: {
                  title: 'Sportangebot',
                  subtitle: 'bearbeiten',
                  show: false,
                  back_link: '/verein/sportangebote/bearbeiten'
                }
              }
          },
          {
            path: 'mein-verein',
            component: () => import('@/views/Verein/Vereine/verein.vue'),
            meta: {
              requiresAuth: true,
              verein: true,
              nav: {
                title: 'Mein Verein',
                subtitle: 'bearbeiten',
                show: true,
                back_link: '/verein'
              }
            }
          },
          {
            path: 'mitarbeiter',
            component: () => import('@/views/Verein/Mitarbeiter/index.vue'),
            meta: {
              requiresAuth: true,
              verein: true,
              nav: {
                title: 'Vereinsmitarbeiter',
                subtitle: 'Übersicht',
                show: true,
                back_link: '/verein'
              }
            }
          },
          {
            path: 'mitarbeiter/:uid',
            component: () => import('@/views/Verein/Mitarbeiter/mitarbeiter.vue'),
            meta: {
              requiresAuth: true,
              verein: true,
              nav: {
                title: 'Mitarbeiter',
                subtitle: 'bearbeiten',
                show: true,
                back_link: ''
              }
            }
          },
          {
            path: 'mein-profil',
            name: 'Mein Profil',
            component: () => import('@/views/Verein/Account/Uebersicht.vue'),
            meta: {
              requiresAuth: true,
              nav: {
                title: 'Mein Profil',
                subtitle: 'bearbeiten',
                show: true,
                back_link: ''
              }
            }
          },
          {
            path: '*',
            name: '404',
            component: () => import('@/views/404.vue'),
            meta: {
              requiresAuth: true,
            }
          }
        ],
      },
      {
        path: '/aktivierung',
        name: 'Account-Aktivierung',
        component: () => import('@/views/Aktivierung.vue')
      },
      {
        path: '/mailaktivierung',
        name: 'E-Mail-Aktivierung',
        component: () => import('@/views/Vereinsadmin/Aktivierung.vue')
      },
      {
        path: '/:verein/angebot-einreichen',
        name: 'Angebot-einreichen',
        component: () => import('@/views/Vereinsadmin/Angebotserstellung.vue')
      }
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Account/Login.vue'),
  },
  {
    path: '/profil-vervollständigen',
    name: 'Profil-vervollständigen',
    component: () =>
      import('@/views/Account/Profil-vervollstaendigen.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next()
})

export default router
