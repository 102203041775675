<template>
    <v-navigation-drawer v-model="user.mobile_nav" app floating width="350px" color="#CFD8DC" :permanent="$vuetify.breakpoint.lgAndUp" style="overflow-y: scroll;">
        <v-row justify="center" class="my-5">
            <v-col cols="11">
                <v-img contain src="/img/800w/Vereinsverwaltung.png"></v-img>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-5" v-if="user.data.verein.farben">
            <v-col cols="11">
                <v-card class="rounded-xl" :color="user.data.verein.farben.primary" to="/verein/mein-profil">
                    <v-row class="px-3" :style="`color: ${user.data.verein.farben.secondary};`">
                        <v-col cols="auto" align-self="center">
                            <v-avatar size="60" tile>
                                <v-img :src="user.data.verein.logo"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col align-self="center">
                            <h4 class="font-weight-light">{{user.data.verein.name}}</h4>
                            <h2>{{user.data.vorname}} {{user.data.nachname}}</h2>
                        </v-col>
                        <v-col cols="auto" align-self="center">
                            <font-awesome-icon icon="fa-thin fa-angle-right" size="xl" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center" class="my-5 py-5">
            <v-col cols="11" class="my-3" v-for="(item, index) in items" :key="index">
                <v-card class="rounded-xl sidebar-nav primary--text" color="transparent" :class="item.expanded ? 'sidebar-nav-active' : ''" flat>
                    <v-row class="px-3" @click="item.children && $route.fullPath.includes(item.link) ? undefined : item.expanded = !item.expanded">
                        <v-col cols="auto" align-self="center">
                            <font-awesome-icon :icon="item.icon" class="ml-2" size="xl" />
                        </v-col>
                        <v-col align-self="center">
                            <h2>{{item.title}}</h2>
                        </v-col>
                        <v-col cols="auto" align-self="center">
                            <font-awesome-icon v-if="!(item.children && $route.fullPath.includes(item.link))" :icon="item.expanded ? 'fa-light fa-chevron-up' : 'fa-light fa-chevron-down'" size="lg" />
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row class="px-3" justify="end" v-if="item.children && $route.fullPath.includes(item.link) || item.expanded">
                            <v-col cols="11" v-for="(child, cindex) in item.children" :key="'child-'+index+'-'+cindex">
                                <v-btn :to="child.link" exact rounded text color="primary">
                                    {{child.title}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </v-card>
            </v-col>
            <v-col cols="11" class="my-3" v-if="user.data.rechte && user.data.rechte.admin">
                <v-card @click="hide()" class="rounded-xl sidebar-nav primary--text" to="/verein/mein-verein" color="transparent" active-class="sidebar-nav-active" flat>
                    <v-row class="px-3">
                        <v-col cols="auto" align-self="center">
                            <font-awesome-icon icon="fa-duotone fa-shield" class="ml-2" size="xl" />
                        </v-col>
                        <v-col align-self="center">
                            <h2>Mein Verein</h2>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="11" class="my-3" v-if="user.data.rechte && (user.data.rechte.admin || user.data.rechte.abteilungsleiter)">
                <v-card class="rounded-xl sidebar-nav primary--text" to="/verein/mitarbeiter" color="transparent" active-class="sidebar-nav-active" flat>
                    <v-row class="px-3">
                        <v-col cols="auto" align-self="center">
                            <font-awesome-icon icon="fa-duotone fa-people-group" class="ml-2" size="xl" />
                        </v-col>
                        <v-col align-self="center">
                            <h2>Vereinsmitarbeiter</h2>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-footer :fixed="$vuetify.breakpoint.lgAndUp" color="#CFD8DC" class="py-5">
            <v-row justify="center">
                <v-col cols="11" class="px-0">
                    <v-btn color="primary" text rounded x-large @click="logout()">
                        <font-awesome-icon icon="fa-duotone fa-right-from-bracket" class="mr-2" size="xl" />
                        Logout
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '../../../auth/auth'
import router from '../../../router'

export default {
    name: 'Vereinsverwaltung-Sidebar',
    data(){
        return {
            items: [
                {
                    icon: 'fa-duotone fa-cubes-stacked',
                    title: 'Sportangebote',
                    link: '/verein/sportangebote',
                    children: [
                        {
                            title: 'Übersicht',
                            link: '/verein/sportangebote'
                        },
                        {
                            title: 'Bearbeitung',
                            link: '/verein/sportangebote/bearbeiten'
                        },
                        {
                            title: 'Teilnehmer',
                            link: '/verein/sportangebote/teilnehmer'
                        }
                    ],
                    expanded: false
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
        user: 'user',
        auth: 'auth',
        }),
    },
    mounted(){
        if(!this.user.data?.rechte?.admin){
            router.push('/')
        }
    },
    methods: {
        logout() {
            logout.logout(this.auth)
        },
        hide(){
            this.items.forEach(item => {
                item.expanded = false
            })
        }
    }
}
</script>

<style scoped>
    .sidebar-nav:hover{
        background-color: #74709540 !important;
    }
    
    .sidebar-nav-active{
        background-color: #74709540 !important;
    }
</style>